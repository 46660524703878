import React, { createContext, useState, useContext, ReactNode } from 'react';

// Create a Context
const ApiVersionContext = createContext<{
    apiVersion: string;
    setApiVersion: (apiVersion: string) => void;
}>({
    apiVersion: '',
    setApiVersion: (apiVersion: string) => {}
});

// Create a Provider component
export const ApiVersionProvider = ({ children }: { children: ReactNode}) => {
  const [apiVersion, setApiVersion] = useState('');

  return  <ApiVersionContext.Provider value={{ apiVersion, setApiVersion }}>{children}</ApiVersionContext.Provider>;
  
};

// Custom hook to use the header context
export const useApiVersion = () => useContext(ApiVersionContext);