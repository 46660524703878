import moment from 'moment';
import { APP_VERSION } from '../../constants';
import { useApiVersion } from 'context/api_version';

export const Footer = () => {
  const { apiVersion } = useApiVersion();
  const CURRENT_YEAR = moment().format('YYYY');
  return (
    <div className='flex flex-col align-middle justify-center bg-gray-100 h-10 fixed bottom-0 left-0 right-0 text-center'>
      &copy; {CURRENT_YEAR}, PeakPro Financial - All Rights Reserved - versions: {APP_VERSION}(FE) - {apiVersion}(BE)
    </div>
  );
};
