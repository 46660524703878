import 'react-toastify/dist/ReactToastify.min.css';
import { QueryClientProvider, QueryClient } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { Main } from './pages';
import LogRocket from 'logrocket';
import { APP_VERSION } from './constants';
import { ApiVersionProvider } from 'context/api_version';

const App = () => {
  const queryClient = new QueryClient();
  LogRocket.init('xzxsrg/retirement-front', { release: APP_VERSION });

  return (
    <QueryClientProvider client={queryClient}>
      <ReactQueryDevtools initialIsOpen={false} />
      <ApiVersionProvider>
        <Main />
      </ApiVersionProvider>
    </QueryClientProvider>
  );
};

export default App;
